import React from "react";

import { useTimer } from "../../hooks/useTimer";

import TimerBoxComp from "./TimerBoxComp";

function TimerComp() {
  const [days, hours, minutes, seconds] = useTimer("2025-01-03");
  return (
    <div className="flex flex-wrap gap-1 z-100">
      <TimerBoxComp value={days} label="days" />
      <TimerBoxComp value={hours} label="hours" />
      <TimerBoxComp value={minutes} label="minutes" />
      <TimerBoxComp value={seconds} label="seconds" />
    </div>
  );
}

export default TimerComp;
