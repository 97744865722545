import React, { useState, useEffect } from "react";
import Layout, { scrollToEl } from "../components/Layout/Layout";
import Swiper from "../components/HeroSwiper/HeroSwiper";
import SplitText from "../components/utils";

import Slide1 from "../images/home/main-slider/slide-1.jpg";
import Slide2 from "../images/home/main-slider/slide-2.jpeg";
import Slide3 from "../images/home/main-slider/slide-3.jpg";
import Slide4 from "../images/home/main-slider/slide-4.jpeg";
import Slide5 from "../images/home/main-slider/slide-5.jpg";
import Slide6 from "../images/home/main-slider/slide-6.jpg";

import Rider from "../images/home/rider.jpg";
import WU from "../images/home/agm_24.jpg";
import Logo from "../images/logo-min.png";

import Helmet from "../images/home/missions/racing-helmet.svg";
import Engine from "../images/home/missions/car-engine.svg";
import Wheel from "../images/home/missions/wheel.svg";

import Testimonial from "../images/home/testimonial/testimonial.jpg";

import Tilt from "react-parallax-tilt";

import "./index.css";

import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";
import TimerComp from "../components/Timer/TimerComp";
import Testimonials from "../components/Testimonials/Testimonials";
import TeamSwiper from "../components/TeamSwiper/TeamSwiper";
import { rideData } from "../components/RideCard/ride-data";
import RideCard from "../components/RideCard/RideCard";
import { navigate } from "gatsby";
import Gallery from "../components/Gallery/Gallery";

export default function IndexPage() {
  const [slideData] = useState([Slide1, Slide2, Slide3, Slide4, Slide5, Slide6]);
  const control = useAnimation();
  const [ref, inView] = useInView();

  const boxVariant = {
    visible: {
      opacity: 1,
      transform: "translateY(0%)",
      transition: { duration: 0.2 },
    },
    hidden: { opacity: 0, transform: "translateY(100%)" },
  };

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Layout>
      <div className="absolute top-0 w-full md:fixed -z-1">
        <Swiper slideData={slideData} />
      </div>

      <section className="relative z-10 flex flex-col justify-center bg-transparent hero-section">
        <div className="container relative z-10 px-4 mx-auto md:px-0">
          <span className="block my-8 text-xl text-white font-regular">
            RIDE AND LIVE TODAY
          </span>
          <h1 className="my-8 font-semibold text-white text-8xl">
            <SplitText text="WE RIDE" /> <br />
            <SplitText text="TOGETHER" />
          </h1>
          <button
            onClick={() => navigate("ride-report")}
            className="px-8 py-4 text-2xl text-white explore-us w-80 bg-kr-orange hover:bg-kr-dark-orange font-regular"
          >
            Explore Our Rides
          </button>
        </div>
      </section>

      <section className="relative bg-neutral-800">
        <div className="absolute z-10 items-stretch hidden w-full h-full md:flex">
          <div className="w-1/2 bg-neutral-700"></div>
          <div className="w-1/2 bg-white"></div>
        </div>
        <div className="container relative z-20 mx-auto md:flex">
          <div className="md:flex-1 md:py-12 py-4 min-h-[250px] flex flex-col justify-center relative z-20 px-4 md:px-0">
            <motion.div
              animate={control}
              ref={ref}
              initial="hidden"
              variants={boxVariant}
            >
              <span className="hidden md:block absolute left-[-15%] bottom-[-52%] font-bold text-neutral-800 text-[100px] z-[-1]">
                <Tilt tiltMaxAngleX={10} tiltMaxAngleY={10} perspective={1000}>
                  UPCOMING
                </Tilt>
              </span>
              <h2 className="relative text-6xl font-semibold text-white upcoming-event">
                UPCOMING EVENT
              </h2>
              <h6>
                <pre className="text-2xl agm font-regular text-kr-orange">
                  ANNUAL GENERAL MEET 2024     JANUARY 03-01-2025
                </pre>
              </h6>
              <TimerComp />
            </motion.div>
          </div>
          <div className="flex flex-wrap items-center md:flex-1">
            <div className="relative flex flex-wrap gap-8 p-8 bg-white md:flex-nowrap">
              <div className="w-full h-full">
                <img src={WU} alt="WU" className="object-cover h-full" />
              </div>
              <h2 className="relative text-4xl font-regular text-kr-orange upcoming-event">
                COUNTDOWN BEGINS
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="relative z-20 px-4 py-12 md:py-36 bg-neutral-800 md:px-0">
        <div className="container relative flex flex-wrap mx-auto">
          <div className="w-full md:w-2/3">
            <h1 className="text-white font-regular text-7xl">
              WELCOME TO THE HOME OF, <br />{" "}
              <span className="text-kr-orange">KOLKATA RIDERS</span>
            </h1>
            <p className="py-12 font-sans text-xl text-neutral-400">
              If you are looking to find a group of well-established and safe to
              ride with, you’ve found us. We were founded as a formal motorcycle
              club in Kolkata in 2012, and are still going strong. We focus on
              safe yet fun riding. Come to a meeting and check us out. Feel free
              to email us with any questions you may have.
            </p>
            <div className="flex flex-wrap justify-between gap-8">
              <div className="flex flex-col w-full gap-2 md:w-auto font-regular text-kr-orange">
                <span className="text-xl uppercase">Members</span>
                <span className="border-b-4 text-8xl border-kr-orange">
                  90+
                </span>
              </div>
              <div className="flex flex-col w-full gap-2 md:w-auto font-regular text-kr-orange">
                <span className="text-xl uppercase">Total kms by club</span>
                <span className="border-b-4 text-8xl border-kr-orange">
                  100,000+
                </span>
              </div>
              <div className="flex flex-col w-full gap-2 md:w-auto font-regular text-kr-orange">
                <span className="text-xl uppercase">
                  KMS OF RIDING EXPERIENCE
                </span>
                <span className="border-b-4 text-8xl border-kr-orange">
                  500,000+
                </span>
              </div>
            </div>
          </div>
          <div className="py-12 md:pl-12 md:flex-1 md:py-auto">
            <Tilt tiltMaxAngleX={10} tiltMaxAngleY={10} perspective={1000} className="hidden md:block">
              <div className="tiltComponent">
                <img
                  src={Logo}
                  alt="Kolkata Riders"
                  className="opacity-10 grayscale"
                />
              </div>
            </Tilt>
            <img
                  src={Logo}
                  alt="Kolkata Riders"
                  className="opacity-10 grayscale md:hidden"
                />
          </div>
        </div>
      </section>

      <section className="relative bg-neutral-800">
        <div className="container flex flex-wrap mx-auto">
          <div className="flex flex-col flex-wrap justify-center w-full p-12 bg-white md:flex-1">
            <h3 className="text-5xl font-regular">
              BECOME ONE OF US <br />
              <span className="text-kr-orange"> JOIN THE CLUB </span>
            </h3>
            <button className="px-8 py-4 mt-12 text-xl text-white uppercase font-regular bg-kr-orange hover:bg-kr-dark-orange">
              Contact Us
            </button>
          </div>
          <div className="my-4 md:flex-1 md:my-auto">
            <div className="w-full">
              <img src={Rider} alt="Rider" />
            </div>
          </div>
        </div>
      </section>

      <section className="relative px-4 py-12 text-center bg-neutral-800 md:py-36 md:px-0">
        <div className="container mx-auto font-regular">
          <h6 className="my-4 text-2xl font-light text-white uppercase before:content-['♦'] before:text-kr-orange after:content-['♦'] after:text-kr-orange before:pr-4 after:pl-4">
            what we do
          </h6>
          <h1 className="my-4 font-semibold text-white uppercase text-7xl">
            OUR MISSIONS
          </h1>

          <div className="flex flex-wrap justify-between mt-4 md:mt-24 md:flex-nowrap">
            <div className="flex flex-col flex-wrap items-center justify-center p-12 card">
              <img src={Helmet} alt="Helmet" className="w-24" />
              <span className="my-8 text-4xl text-white font-regular">
                RIDE OUTS
              </span>
              <p className="font-sans text-neutral-400">
                Kolkata Riders regularly organizes ride-outs suitable for all
                types of bikes and speeds. Our ride-outs range from short, local
                routes, to country wide travel.
              </p>
            </div>
            <div className="flex flex-col flex-wrap items-center justify-center p-12 card">
              <img src={Engine} alt="Engine" className="w-24" />
              <span className="my-8 text-4xl text-white font-regular">
                SUPPORT
              </span>
              <p className="font-sans text-neutral-400">
                Establish a brotherhood of other like minded bikers who take an
                oath to stand with us, to support and defend the Constitution
                against all enemies, foreign and domestic.
              </p>
            </div>
            <div className="flex flex-col items-center justify-center p-12 card">
              <img src={Wheel} alt="Wheel" className="w-24" />
              <span className="my-8 text-4xl text-white font-regular">
                COMMUNITIES
              </span>
              <p className="font-sans text-neutral-400">
                Provide an opportunity to give back to communities as well as
                provide assistance to veterans related injuries via charities,
                benefits, and donations.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="relative px-4 pb-12 bg-neutral-800 md:pb-36 md:px-0">
        <div className="container relative flex flex-wrap gap-12 mx-auto">
          <div className="md:flex-1">
            <h6 className="my-4 text-2xl font-light text-white uppercase before:content-['♦'] before:text-kr-orange before:pr-4">
              OUR TEAM
            </h6>
            <h1 className="font-semibold text-white text-7xl">
              MEET OUR <br /> CREW <br /> MEMBERS
            </h1>
          </div>
          <div className="flex flex-wrap justify-end w-full md:w-2/3">
            <TeamSwiper />
          </div>
        </div>
      </section>

      <section className="relative pb-12 bg-neutral-800 md:pb-36">
        <div className="container relative flex mx-auto max-h-[800px]">
          <div className="hidden w-full md:flex md:w-1/2">
            <div className="w-full h-full">
              <img src={Testimonial} className="object-cover w-full h-full" />
            </div>
          </div>
          <div className="w-full p-8 bg-white md:p-12 md:w-1/2">
            <h6 className="my-4 text-2xl font-light  uppercase before:content-['♦'] before:text-kr-orange before:pr-4">
              TESTIMONIALS
            </h6>
            <h1 className="text-4xl font-semibold md:text-7xl">
              WHAT OUR <br /> MEMBERS SAY
            </h1>

            <div className="relative">
              <Testimonials />
            </div>
          </div>
        </div>
      </section>

      <section className="relative pb-12 bg-neutral-800 md:pb-36">
        <div className="container relative flex-col flex-wrap items-center px-4 mx-auto md:flex md:px-0">
          <h6 className="text-2xl font-light text-white uppercase before:content-['♦'] before:text-kr-orange after:content-['♦'] after:text-kr-orange before:pr-4 after:pl-4">
            RIDING REPORT
          </h6>
          <h1 className="my-4 font-semibold text-white uppercase text-7xl">
            OUR RIDES
          </h1>
          <div className="grid w-full grid-cols-1 gap-8 py-12 md:grid-cols-3">
            {rideData.map((data, index) => {
              return (
                <>
                  {index < 3 && (
                    <RideCard
                      img={data.img}
                      title={data.title}
                      description={data.description}
                      date={data.date}
                    />
                  )}
                </>
              );
            })}
          </div>
          {rideData.length > 3 && (
            <div className="w-full text-center">
              <button
                className="px-4 py-4 text-2xl text-white uppercase font-regular hover:bg-kr-dark-orange bg-kr-orange"
                onClick={() => navigate("ride-report")}
              >
                Explore More
              </button>
            </div>
          )}
        </div>
      </section>

      <section className="relative pb-24 mt-24 md:mt-64 bg-neutral-800 md:pb-36">
        <div className="bg-overlay absolute md:-top-64 -top-24 left-0 w-full bg-gallery bg-center bg-fixed bg-cover md:h-[450px] h-[200px]"></div>
        <div className="container relative flex flex-col items-center mx-auto">
          <h6 className="text-2xl font-light text-white uppercase before:content-['♦'] before:text-kr-orange after:content-['♦'] after:text-kr-orange before:pr-4 after:pl-4">
            OUR GALLERY
          </h6>
          <div className="relative flex w-full">
            <Gallery />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const Head = () => <title>Home Page</title>;
